import axios from "@evercam/shared/api/client/axios"
import type {
  MilesightCameraVideoConfig,
  MilesightRequestParams,
  MilesightCameraNetworkConfiguration,
  MilesightCameraSystemSettings,
  MilesightCameraOsdConfiguration,
  MilesightCameraSdCardInfo,
  TimelineEventCount,
  TimelapseReport,
  TimelapseReportFilters,
  CopilotConversation,
  CopilotMessage,
  PaginatedItems,
  CopilotMessageStep,
  DetectionsFilters,
  TrackingsByLabel,
  CameraExid,
  CountsParams,
  CountByPeriod,
  DetectionsPresenceByLabel,
  FeedbackPayload,
  LabsPaginationParams,
  ProjectExid,
  CopilotTimelapsesAvailabilityByPeriod,
  CopilotTimelapse,
} from "@evercam/shared/types"

export const EvercamLabsApi = {
  milesight: {
    getVideoConfig(
      { host, httpPort, username, password }: MilesightRequestParams,
      config = {}
    ): Promise<MilesightCameraVideoConfig> {
      return axios.get(`${axios.env.evercamLabsUrl}/milesight/video`, {
        params: {
          host,
          port: httpPort,
          username,
          password,
        },
        ...config,
      })
    },
    getNetworkConfiguration(
      { host, httpPort, username, password }: MilesightRequestParams,
      config = {}
    ): Promise<MilesightCameraNetworkConfiguration> {
      return axios.get(`${axios.env.evercamLabsUrl}/milesight/network`, {
        params: {
          host,
          port: httpPort,
          username,
          password,
        },
        ...config,
      })
    },
    getSystemSettings(
      { host, httpPort, username, password }: MilesightRequestParams,
      config = {}
    ): Promise<MilesightCameraSystemSettings> {
      return axios.get(`${axios.env.evercamLabsUrl}/milesight/system`, {
        params: {
          host,
          port: httpPort,
          username,
          password,
        },
        ...config,
      })
    },
    getTimeSettings(
      { host, httpPort, username, password }: MilesightRequestParams,
      config = {}
    ): Promise<MilesightCameraSystemSettings> {
      return axios.get(`${axios.env.evercamLabsUrl}/milesight/time`, {
        params: {
          host,
          port: httpPort,
          username,
          password,
        },
        ...config,
      })
    },
    getOsdConfiguration(
      { host, httpPort, username, password }: MilesightRequestParams,
      config = {}
    ): Promise<MilesightCameraOsdConfiguration> {
      return axios.get(`${axios.env.evercamLabsUrl}/milesight/osd`, {
        params: {
          host,
          port: httpPort,
          username,
          password,
        },
        ...config,
      })
    },
    getSdCardInfo(
      { host, httpPort, username, password }: MilesightRequestParams,
      config = {}
    ): Promise<MilesightCameraSdCardInfo> {
      return axios.get(`${axios.env.evercamLabsUrl}/milesight/storage`, {
        params: {
          host,
          port: httpPort,
          username,
          password,
        },
        ...config,
      })
    },
  },
  timelapses: {
    getTimelapse(params: {
      projectExid: ProjectExid
      timelapseId: number
    }): Promise<CopilotTimelapse> {
      return axios.get(
        `${axios.env.evercamLabsUrl}/projects/${params.projectExid}/timelapses/${params.timelapseId}`,
        {
          preserveCasing: true,
        }
      )
    },
    getAvailableTimelapses(
      projectExid: ProjectExid,
      params
    ): Promise<CopilotTimelapsesAvailabilityByPeriod> {
      return axios.get(
        `${axios.env.evercamLabsUrl}/projects/${projectExid}/timelapses`,
        {
          params,
          preserveCasing: true,
        }
      )
    },
  },
  timelapseReports: {
    getReports(
      params: TimelapseReportFilters
    ): Promise<Array<TimelapseReport>> {
      const { projectExid, ...otherParams } = params

      return axios.get(
        `${axios.env.evercamLabsUrl}/projects/${params.projectExid}/tl-reports`,
        {
          params: otherParams,
          preserveCasing: true,
        }
      )
    },
    getReportsCounts(
      params: TimelapseReportFilters
    ): Promise<Array<TimelineEventCount>> {
      const { projectExid, ...otherParams } = params

      return axios.get(
        `${axios.env.evercamLabsUrl}/projects/${params.projectExid}/tl-reports/counts`,
        {
          params: otherParams,
          preserveCasing: true,
        }
      )
    },
  },
  copilot: {
    async getAllConversations(
      params: LabsPaginationParams & {
        cameraExid?: string
        projectExid?: string
        user?: string
      }
    ): Promise<PaginatedItems<CopilotConversation>> {
      return axios.get(`${axios.env.evercamLabsUrl}/copilot/conversations`, {
        params,
        preserveCasing: true,
      })
    },
    async getConversationMessages(
      conversationId: number,
      params: LabsPaginationParams
    ): Promise<PaginatedItems<CopilotMessage>> {
      return axios.get(
        `${axios.env.evercamLabsUrl}/copilot/conversations/${conversationId}/messages`,
        { params, preserveCasing: true }
      )
    },
    async getMessageSteps(
      message: CopilotMessage
    ): Promise<CopilotMessageStep[]> {
      return axios.get(
        `${axios.env.evercamLabsUrl}/copilot/conversations/${message.conversationId}/messages/${message.id}/steps`
      )
    },
    async submitMessageFeedback({
      conversationId,
      messageId,
      ...params
    }: FeedbackPayload) {
      return axios.post(
        `${axios.env.evercamLabsUrl}/copilot/conversations/${conversationId}/messages/${messageId}/feedback`,
        params,
        {
          preserveCasing: true,
        }
      )
    },
  },
  detections: {
    async getDetectionsTracking(
      cameraExid: CameraExid,
      params: DetectionsFilters
    ): Promise<TrackingsByLabel> {
      return axios.get(
        `${axios.env.evercamLabsUrl}/cameras/${cameraExid}/detections/tracking`,
        {
          params,
          preserveCasing: true,
        }
      )
    },
    async getDetectionsPresenceDateIntervals({
      cameraExid,
      fromDate,
      toDate,
      precision,
    }: CountsParams): Promise<DetectionsPresenceByLabel> {
      return axios.get(
        `${axios.env.evercamLabsUrl}/cameras/${cameraExid}/detections/presence-intervals`,
        {
          params: { fromDate, toDate, precision },
          preserveCasing: true,
        }
      )
    },
    async getCranesMovement({
      cameraExid,
      fromDate,
      toDate,
      precision,
    }: CountsParams): Promise<CountByPeriod[]> {
      return axios.get(
        `${axios.env.evercamLabsUrl}/cameras/${cameraExid}/detections/movement`,
        {
          params: { fromDate, toDate, precision },
          preserveCasing: true,
        }
      )
    },
  },
  googleCloudStorage: {
    listFiles(
      prefix: string = ""
    ): Promise<{ files: string[]; folders: string[] }> {
      return axios.get(
        `${axios.env.evercamLabsUrl}/gc-storage/buckets/evercam-labs/files`,
        {
          params: {
            prefix,
          },
          preserveCasing: true,
        }
      )
    },
    getSignedUrl(fileName: string): Promise<{ url: string }> {
      return axios.get(
        `${axios.env.evercamLabsUrl}/gc-storage/buckets/evercam-labs/files/signed-url`,
        {
          params: {
            fileName,
          },
          preserveCasing: true,
        }
      )
    },
  },
}
